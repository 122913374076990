import React, { Component } from "react";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";
import ReactPlayer from "react-player";
import Layout from "../../../../components/layout";
import SEO from "../../../../components/seo";
import styles from "./out-of-state-video.module.css";
import {
  setCurrentModule,
  setCurrentStep,
  saveLocalStateToGlobalState
} from "../../../../state/actions";
import { func } from "prop-types";

class ProcessIndex extends Component {
  state = this.props.savedState || {
    hasWatchedVideo: false
  };

  static propTypes = {
    handleNavigate: func,
    goToStep: func
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(2);
    this.props.goToStep(3);
  }

  goForward = () => {
    navigate("/modules/travel/process/out-of-state-video");
  };

  goBack = () => {
    navigate("/modules/travel/process/");
  };

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Travel - Process"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          <div className={styles.videoContainer}>
            <ReactPlayer
              ref={c => (this.playerRef = c)}
              className={styles.reactPlayer}
              url="https://www.youtube.com/embed/cdMM1ru07b0"
              height="100%"
              width="100%"
              controls
              onEnded={() =>
                this.setState({ hasWatchedVideo: true }, () => {
                  this.playerRef.seekTo(0);
                  this.props.saveLocalStateToGlobalState(
                    {
                      hasWatchedVideo: this.state.hasWatchedVideo
                    },
                    {
                      moduleId: 2,
                      stepId: 3
                    }
                  );
                })
              }
            />
          </div>
          <div className={styles.backButtonArea}>
            <Button
              onClick={this.goBack}
              variant="success"
              icon={IconArrowOpenStart}
            />
          </div>
          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.goForward}
              variant="success"
              icon={IconArrowOpenEnd}
              disabled={!this.state.hasWatchedVideo}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  savedState: state.modules.find(x => x.id === 2).steps.find(x => x.id === 3)
    .localState,
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    },
    saveLocalStateToGlobalState(state, info) {
      dispatch(saveLocalStateToGlobalState(state, info));
    }
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(ProcessIndex);
